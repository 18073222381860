.container-recuperacion-one {
  background-image: url('../images/body.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



.container-recuperacion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-bottom: -100px;
}

.form-recuperacion {
  background-color: #190019;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.form-recuperacion label {
  color: #fff;
  display: block;
  margin-bottom: 0.5rem;
}

.form-recuperacion input {
  width: 93%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #FBE4D8;
  color: #190019;
}

.form-recuperacion input::placeholder {
  color: #190019;
}

.button-recuperacion {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  background-color: #522B52;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-recuperacion:hover {
  background-color: #854F6C;
}

  