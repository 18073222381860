/* Auth.css */
.contenedor-principal-auth {
    background-image: url('../images/background.svg');
    background-size: cover; /* Ajusta la imagen automáticamente al tamaño de la pantalla */
    background-position: center center; /* Centra la imagen vertical y horizontalmente */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-attachment: fixed; 
  }
  
  .auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55vh;
    margin-bottom: -100px;
  }
  
  .auth-form {
    background-color: #4a4a4a;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
  }
  
  .auth-form h2 {
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    color: white;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-group input {
    width: 92%;
    padding: 0.8rem;
    border: 1px solid #522B52;
    border-radius: 5px;
    background-color: #ffc0cb;
    color: black;
  }
  
  .form-group input::placeholder {
    color: black;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    background-color: #fc7ebc;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #ffc0cb;
  }
  
  .toggle-auth {
    text-align: center;
    margin-top: 1rem;
  }
  
  .toggle-auth p {
    color: white;
  }
  
  .toggle-auth span {
    color: #fc7ebc;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .alert {
    color: #DFB6B2;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* Media queries para dispositivos móviles */
  @media (max-width: 768px) {
    .auth-form {
      padding: 1.5rem;
      max-width: 92%;
    }
  
    .auth-form h2 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .form-group input {
      padding: 0.6rem;
    }
  
    .submit-button {
      padding: 0.6rem;
      font-size: 0.9rem;
      width: 97%;
    }
  }
  
  @media (max-width: 480px) {
    .auth-form {
      padding: 1rem;
    }
  
    .auth-form h2 {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
  
    .form-group input {
      padding: 0.5rem;
    }
  
    .submit-button {
      padding: 0.5rem;
      font-size: 0.8rem;
    }
  }

  
  
  
  


  
  