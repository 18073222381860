.container-register-one{
  background-image: url('../images/body.jpeg'); 
  background-size: cover; /* Ajusta la imagen automÃ¡ticamente al tamaÃ±o de la pantalla */
  background-position: center center; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed; 
}

.container-register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-bottom: -100px;
}

.form-register {
  background-color: #4a4a4a; /* Fondo del formulario */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.input-register {
  width: 93%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #444; /* Borde oscuro */
  border-radius: 5px;
  background-color: #ffc0cb; /* Fondo de entrada */
  color: #190019;
}

.input-register::placeholder {
  color: #190019; /* Placeholder color */
}

.button-register {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  background-color: #fc7ebc; /* Botón de acento */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-register:hover {
  background-color: #ffc0cb; /* Color del botón al pasar el ratón */
}

.form-title-register {
  color: #fff;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.alert {
  color: #d23669;
  text-align: center;
  margin-bottom: 1rem;
}

.login-redirect {
  text-align: center;
  margin-top: 1rem;
}

.login-link {
  color: #fc7ebc; /* Color del enlace */
  cursor: pointer;
  text-decoration: underline;
}
.login-question {
  color: #fff; /* Color blanco */
}

