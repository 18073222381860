/* Contenedor principal */
.admin-panel-contenedor {
  background-image: url('../images/body.jpeg');
  background-size: cover; /* Ajusta la imagen automáticamente al tamaño de la pantalla */
  background-position: center center; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed; 
}

/* Panel de administración */
.admin-panel {
  padding: 20px;
  background-color: white;
}

/* Título del panel */
.panel-administri {
  text-align: center;
}

/* Secciones */
.sections {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.section {
  width: 45%;
}

/* Formulario de agregar trabajador */
.add-worker {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

/* Listas */
.worker-list ul,
.appointment-list ul {
  list-style-type: none;
  padding: 0;
}

.worker-list li,
.appointment-list li {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Alinea verticalmente los elementos */
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  cursor: pointer;
}

/* Detalles de citas */
.cita-details {
  margin-top: 10px;
}

.service-details,
.user-details {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.service-details p,
.user-details p {
  margin: 5px 0;
}

/* Estilos para botones de disponibilidad */
.btn-worker-toggle,
.btn-worker-delete {
  margin-left: auto; /* Empuja los botones hacia la derecha */
}

/* Botones de navegación */
.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.navigation-buttons button:hover {
  background-color: #854F6C;
}

/* Botones generales */
.btn {
  background-color: #2B124C;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #522B52;
}

.btn-primary {
  background-color: #190019;
}

.btn-primary:hover {
  background-color: #854F6C;
}

.btn-delete {
  background-color: #DFB6B2;
  color: #190019;
}

.btn-delete:hover {
  background-color: #FBE4D8;
  color: #854F6C;
}

.btn-worker-add,
.btn-worker-toggle {
  background-color: #522B52;
}

.btn-worker-add:hover,
.btn-worker-toggle:hover {
  background-color: #854F6C;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .sections {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }

  .add-worker {
    gap: 5px;
  }

  .worker-list li,
  .appointment-list li {
    flex-wrap: wrap;
    padding: 10px;
  }

  .btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Estilos adicionales para asegurar que los botones estén al fondo */
.section .add-worker {
  margin-bottom: 0;
}

.section .worker-list {
  margin-bottom: 20px;
}

.section .navigation-buttons {
  margin-top: 20px;
}


.panel-footer {
  background-color: #333; /* Color de fondo del pie de página */
  color: #fff; /* Color del texto */
  padding: 20px; /* Espaciado interno */
  text-align: center; /* Alinear el texto al centro */
}

.panel-footer .footer-content {
  max-width: 1200px; /* Ancho máximo del contenido */
  margin: 0 auto; /* Centrar el contenido horizontalmente */
}

.panel-footer p {
  margin: 0; /* Eliminar el margen por defecto de los párrafos */
  font-size: 14px; /* Tamaño de la fuente del texto */
}

.panel-footer a {
  color: #fff; /* Color del texto de los enlaces */
  text-decoration: none; /* Eliminar el subrayado de los enlaces */
}

.panel-footer a:hover {
  text-decoration: underline; /* Subrayar los enlaces al pasar el cursor */
}

.panel-footer .footer-icons {
  margin-top: 10px; /* Espaciado superior para los íconos */
}

.panel-footer .footer-icons a {
  color: #fff; /* Color de los íconos */
  margin: 0 10px; /* Espaciado horizontal entre los íconos */
  font-size: 20px; /* Tamaño de los íconos */
  text-decoration: none; /* Eliminar el subrayado de los enlaces */
}

.panel-footer .footer-icons a:hover {
  color: #ddd; /* Color de los íconos al pasar el cursor */
}










  
  