.user-details-container-one {
  background-image: url('../images/background.svg');
  background-size: cover; /* Ajusta la imagen automáticamente al tamaño de la pantalla */
  background-position: center center; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed; 
}
.user-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .user-details-form {
    background-color: #4a4a4a; 
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
  }
  
  .user-details-title {
    color: #fff;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  
  .user-details-input {
    width: 95%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #444; /* Borde oscuro */
    border-radius: 5px;
    background-color: #ffc0cb; /* Fondo de entrada */
    color: black;
  }
  
  .user-details-input::placeholder {
    color: black; /* Color del placeholder */
  }
  
  .user-details-button {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    background-color: #fc7ebc; /* Botón de acento */
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .user-details-button:hover {
    background-color: #ffc0cb; /* Color del botón al pasar el ratón */
  }



  /* UserDetailsForm.css */

.terms-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.terms-message {
  font-size: 14px;
  margin-bottom: 10px;
}

.read-more {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.read-more-content {
  display: none;
  font-size: 14px;
  margin-top: 10px;
}

.terms-checkbox {
  margin-right: 10px;
}

.terms-label {
  display: flex;
  align-items: center;
}

.user-details-info {
  margin-top: 20px;
  font-size: 14px;
  color: white;
}

.user-details-read-more {
  color: blue;
  text-decoration: underline;
  margin-left: 5px;
}


/* ULTIMO BOTON */

.button {
  --color: #fff;
  --color-hover: var(--color);
  --background: #2B3044;
  --background-hover: var(--background);
  --hover-back: #6D58FF;
  --hover-front: #5C86FF;
  padding: 8px 28px;
  width: 40%;
  border-radius: 10px;
  line-height: 24px;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: .02em;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  color: var(--c, var(--color));
  background: var(--b, var(--background));
  transition: color .2s linear var(--c-d, .2s), background .3s linear var(--b-d, .2s);
}

.button:not(.simple):before,
.button:not(.simple):after {
  content: '';
  position: absolute;
  background: var(--pb, var(--hover-back));
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  border-radius: var(--br, 40%);
  transform: translateY(var(--y, 50%));
  transition: transform var(--d, .4s) ease-in var(--d-d, 0s), border-radius .5s ease var(--br-d, .08s);
}

.button:not(.simple):after {
  --pb: var(--hover-front);
  --d: .44s;
}

.button div {
  z-index: 1;
  position: relative;
  display: flex;
}

.button div span {
  display: block;
  backface-visibility: hidden;
  transform: translateZ(0);
  animation: var(--name, none) .7s linear forwards .18s;
}

.button.in {
  --name: move;
}

.button.in:not(.out) {
  --c: var(--color-hover);
  --b: var(--background-hover);
}

.button.in:not(.out):before,
.button.in:not(.out):after {
  --y: 0;
  --br: 5%;
}

.button.in:not(.out):after {
  --br: 10%;
  --d-d: .02s;
}

.button.in.out {
  --name: move-out;
}

.button.in.out:before {
  --d-d: .06s;
}

.button.alternative {
  --color-hover: #2B3044;
  background-color: #fc7ebc; /* Botón de acento */
  --hover-back: #ffc0cb;;
  --hover-front: #F6F8FF;
}



@keyframes move {
  30%, 36% {
      transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
  }
  50% {
      transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
  }
  70% {
      transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
  }
}

@keyframes move-out {
  30%, 36% {
      transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
  }
  50% {
      transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
  }
  70% {
      transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
  }
}



@media(max-width: 440px) {
  body {
      flex-direction: column;
  }
}

.button {
  display: block;
  margin: 0 8px;
  margin-top: 20px;
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

@media(max-width: 440px) {
  .button {
      margin: 8px 0;
  }
}

.colorcito {
  color: white;
}





  