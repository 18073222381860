.contenedor-citas {
  background-image: url('../images/body.jpeg');
    background-size: cover; /* Ajusta la imagen automÃ¡ticamente al tamaÃ±o de la pantalla */
    background-position: center center; /* Centra la imagen vertical y horizontalmente */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-attachment: fixed; 
}

.calendario-citas {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #4a4a4a; /* Piel Clara */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.calendario-citas h2 {
  color: black; /* Purpura Oscuro */
  margin-bottom: 20px;
  font-size: 24px;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation button {
  color: #2b124c; /* Purpura */
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: #fc7ebc; /* Morado Piel */
  margin-bottom: 10px;
}

.react-calendar__tile {
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 1.5;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  color: black; /* Días en color negro */
}

.react-calendar__tile--now {
  background: #dfb6b2; /* Piel Crema */
}

.react-calendar__tile--active {
  background: #fc7ebc; /* Purpura */
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fc7ebc; /* Purpura Oscuro */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #854f6c; /* Piel Durazno */
}

.horarios-disponibles {
  margin-top: 20px;
  text-align: center;
}

.horarios-disponibles h3 {
  margin-bottom: 10px;
  color: #190019; /* Purpura Oscuro */
  font-size: 20px;
}

.time-slot {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  font-size: 16px;
  color: #190019; /* Purpura Oscuro */
  border: 2px solid #fc7ebc; /* Purpura */
  border-radius: 5px;
  cursor: pointer;
  background-color: #fbe4d8; /* Piel Clara */
  transition: background-color 0.3s, color 0.3s;
}

.time-slot.selected {
  background-color: #fc7ebc; /* Purpura */
  color: #fbe4d8; /* Piel Clara */
}

.time-slot:hover {
  background-color: #2b124c; /* Purpura */
  color: #fbe4d8; /* Piel Clara */
}

button {
  background-color: #fc7ebc; /* Purpura */
  color: #fbe4d8; /* Piel Clara */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #190019; /* Purpura Oscuro */
}




  
  
  
  