.container-verify-one-one {
  background-image: url('../images/background.svg');
  background-size: cover; /* Ajusta la imagen automáticamente al tamaño de la pantalla */
  background-position: center center; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed; 
}
.container-verify {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.verify-form {
  background-color: #4a4a4a; /* Fondo del formulario */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.verify-title {
  color: #fff;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.verify-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.verify-label {
  color: #fff;
  font-size: 1rem;
  flex: 1;
}

.verify-input {
  flex: 3;
  padding: 0.8rem;
  margin-left: 1rem;
  border: 1px solid #444; /* Borde oscuro */
  border-radius: 5px;
  background-color: #FBE4D8; /* Fondo de entrada */
  color: black;
}

.verify-button {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  background-color: #fc7ebc; /* Botón de acento */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-button:hover {
  background-color: #ffc0cb; /* Color del botón al pasar el ratón */
}

.demora {
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    text-align: center; 
}

.resend-code {
  color: white; /* Color del texto */
  text-align: center;
  margin-top: 1rem;
  cursor: pointer;
}

.resend-code .underline {
  text-decoration: underline;
}

.message {
  color: white; /* Color del mensaje */
  text-align: center;
  margin-top: 1rem;
}

  