/* Encabezado */
.pagina-principal{
    background-image: url('../images/body.jpeg');
    background-size: cover; /* Ajusta la imagen automÃ¡ticamente al tamaÃ±o de la pantalla */
    background-position: center center; /* Centra la imagen vertical y horizontalmente */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-attachment: fixed; 
  }
  .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      background-color: #ffc0cb;
      height: 7rem;
    }
    .logo {
      width: 10rem; /* Ajusta el tamaÃ±o de la imagen segÃºn tus necesidades */
      margin-left: -1rem;
      margin-top: -1.5rem;
    }
  h1 {
      margin: 0;
      color: #4a4a4a;
      margin-top: 2rem;
      margin-right: 1rem;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, Courier, monospace;
    }
    
    
    /* Banner de bienvenida */
    .welcome-banner {
      background: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
      color: white; /* Color de texto */
      padding: 20px;
      border: 1px solid #DFB6B2; /* Borde inferior */
      text-align: center;
      margin-top: 1rem;
    }
    
    
    /* Estilos para el contenedor de opciones */
    .centered-container {
      display: flex;
      justify-content: center; /* Alinea horizontalmente el contenedor en el centro */
      align-items: center; /* Centra verticalmente el contenedor */
    }
    .centered-container button {
      background: #ffc0cb; /* Color de fondo del botÃ³n */
      color: #4a4a4a; /* Color de texto del botÃ³n */
      border: 0.5px solid black; /* Sin borde */
      padding: 10px 100px; /* Espaciado interno del botÃ³n */
      margin: 0.8rem; /* Espaciado entre botones */
      border-radius: 5px; /* Borde redondeado */
      cursor: pointer; /* Cambia el cursor al pasar el mouse */
      font-size: 1rem; /* TamaÃ±o de fuente */
      transition: background 0.3s, color 0.3s; /* TransiciÃ³n de color de fondo y texto al pasar el mouse */
      margin-top: 20rem;
      font-weight: bold;
    }
    .centered-container button:hover{
      background: #fc7ebc;
    }
    /* Estilos para la flecha despuÃ©s de cada botÃ³n */
    .option-button span {
      margin-left: 8px; /* Espaciado entre el texto del botÃ³n y la flecha */
      color: white; /* Color de la flecha (ajusta el color segÃºn tus preferencias) */
      font-size: 1rem; /* TamaÃ±o de la flecha (ajusta el tamaÃ±o segÃºn tus preferencias) */
    }
    
    
    /* Estilos para el pie de pÃ¡gina */
    .footer {
      background: #f8f5f9; /* Color de fondo del pie de pÃ¡gina */
      color: black; /* Color de texto en el pie de pÃ¡gina */
      padding: 5px; /* Espaciado interno del pie de pÃ¡gina */
      text-align: center;
      margin-top: 7rem;
      font-weight: bold;
    }
    .footer a{
      color: black;
      transition: color 0.3s ease;
    }
    .footer p {
      margin-bottom: 2rem; /* Espaciado entre el pÃ¡rrafo y los iconos */
      margin-top: 1rem;
    }
    .footer-menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .footer-option {
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    /* Estilos para las imÃ¡genes */
    .footer-option img {
      width: 80px; /* Ajusta el tamaÃ±o de las imÃ¡genes segÃºn tus preferencias */
      height: 80px; /* Ajusta el tamaÃ±o de las imÃ¡genes segÃºn tus preferencias */
      margin-bottom: 10px; /* Espaciado entre la imagen y el texto */
    }
    .footer a:hover{
      color: #DFB6B2;
    }

    
    
    
    
    
    /* Estilos para pantallas pequeÃ±as (hasta 600px) */
    @media (max-width: 800px) {
      body, html{
        width: 100%;
        height: 100vh;
        background-size: cover; /* Ajusta la imagen automÃ¡ticamente al tamaÃ±o de la pantalla */
        background-position: center center; /* Centra la imagen vertical y horizontalmente */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-attachment: fixed;
      }
      .header {
        flex-direction: column; /* Cambia la direcciÃ³n de los elementos del encabezado */
        align-items: center; /* Centra horizontalmente los elementos del encabezado */
        height: 14rem;
      }
    
      .logo {
        margin-bottom: 10px; /* Espaciado entre el logo y el tÃ­tulo */
      }
    
      h1 {
        position: absolute;
        text-align: center; /* Centra el tÃ­tulo horizontalmente */
        margin-top: 7rem;
        max-width: 9em;
      }
    
      .welcome-banner {
        text-align: center; /* Centra el banner de bienvenida horizontalmente */
        max-width: 25em;
        border-radius: 8px;
        
      }
    
    
    
    /* Estilos para el contenedor de opciones */
    .centered-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around; /* Alinea horizontalmente las opciones */
      align-items: center; /* Centra verticalmente el contenedor */
     
    }
    
    /* Estilos para las opciones (botones) */
    .centered-container button {
      background: #854F6C; /* Color de fondo del botÃ³n */
      color: #fff; /* Color de texto del botÃ³n */
      border: 0.5px solid black; /* Sin borde */
      padding: 10px 70px; /* Espaciado interno del botÃ³n */
      margin: 1px; /* Espaciado entre botones */
      border-radius: 5px; /* Borde redondeado */
      cursor: pointer; /* Cambia el cursor al pasar el mouse */
      font-size: 1rem; /* TamaÃ±o de fuente */
      margin-top: 7%;
      font-weight: bold;
      margin-left: -17px;
    }
    
    
      .footer-menu {
        flex-direction: column; /* Cambia la direcciÃ³n de los elementos del pie de pÃ¡gina */
        
      }
      /* .footer p{
        max-width: 18rem;
      } */
    }
    
    /* Estilos para el bloque de crÃ©ditos */
    .credits {
      text-align: center; /* Centra el texto horizontalmente */
      background: #190019; /* Color de fondo */
      color: #fff; /* Color de texto */
      padding: 10px; /* Espaciado interno */
      font-size: 15px;
    }
    
    .credits a {
      color: #3de2e5; /* Cambia el color del enlace a azul aguamarina */
      font-weight: 100; /* Hace que la palabra "softdrumin" sea mÃ¡s gruesa (ajusta el valor segÃºn tus preferencias) */
    }