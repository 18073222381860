/* src/styles/ServiciosList.css */
.contenedor-servicios {
  background-image: url('../images/servicios/fondo-servicios.svg'); 
  background-size: cover; /* Ajusta la imagen automÃ¡ticamente al tamaÃ±o de la pantalla */
  background-position: center center; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-attachment: fixed; 
}
.servicios-lista {
  margin: 0;
  padding: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: black;
}

.categoria {
  margin-bottom: 20px;
}

.categoria h3 {
  background-color: #fc7ebc;
  color: white;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}

.servicios-container {
  margin-top: 10px;
  padding-left: 20px;
}

.servicio {
  display: flex;
  align-items: center;
  background-color: #ffc0cb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.servicio-info {
  flex-grow: 1;
}

.servicio-info h4 {
  margin: 0;
  color: #190019;
}

.servicio-imagen {
  width: 100px;
  height: auto;
  border-radius: 5px;
  margin-left: 10px;
}

.servicio:hover {
  cursor: pointer;
}

/* Ventana flotante */
.floating-window {
  margin-top: 70px;
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  border: 2px solid #522B52;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  overflow: hidden;
}

.floating-window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.service-count {
  background: #fc7ebc;
  color: white;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  animation: bounce 0.3s ease-in-out;
}

@keyframes bounce {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
}

.selected-services-list {
  margin-top: 10px;
}

.selected-service {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.selected-service button {
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.total {
  margin-top: 20px;
}

.proceed-button {
  background: #fc7ebc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

 



  