.manage-appointments {
    margin: 20px;
  }
  
  .manage-appointments h2 {
    color: #333;
    text-align: center;
  }
  
  .manage-appointments ul {
    list-style-type: none;
    padding: 0;
  }
  
  .manage-appointments li {
    background-color: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .manage-appointments li p {
    margin: 0;
  }
  
  .manage-appointments button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .manage-appointments button:hover {
    background-color: #ff1a1a;
  }
  